import React from 'react';
import Layout from 'client/components/Layout';
import MadeInFrance from 'client/components/MadeInFrance';
import Markdown from 'client/components/Markdown';
import PageContainer from 'client/components/PageContainer';
import TrackPages from 'client/components/TrackPages';
import Divider from 'client/elements/Divider';
import IconButton from 'client/elements/IconButton';
import { KeyboardArrowDownRoundedIcon } from 'client/elements/Icons';
import Typography from 'client/elements/Typography';
import { useTranslation } from 'client/utils/i18n';
import { scrollTo } from 'client/utils/scrollTo';
import { Categories } from 'types/categories';
import { getCategoryUrl } from 'utils/category';
import Category from './Category';
import Gallery, { GalleryProps } from './Gallery';
import styles from './index.module.css';
interface HomeProps {
  categories: Categories;
  gallerySlides: GalleryProps['slides'];
  metaTitle: string;
  metaDescription: string;
  text: string;
}
const Home = ({
  categories,
  gallerySlides,
  metaTitle,
  metaDescription,
  text
}: HomeProps) => {
  const {
    t,
    i18n
  } = useTranslation();
  const containerRef = React.useRef<HTMLDivElement>(null);
  return <Layout title={metaTitle} description={metaDescription} data-sentry-element="Layout" data-sentry-component="Home" data-sentry-source-file="index.tsx">
      <TrackPages data-sentry-element="TrackPages" data-sentry-source-file="index.tsx" />
      <section className={styles.galleryContainer}>
        <Gallery slides={gallerySlides} data-sentry-element="Gallery" data-sentry-source-file="index.tsx" />
        <MadeInFrance className={styles.madeInFrance} color="white" data-sentry-element="MadeInFrance" data-sentry-source-file="index.tsx" />
        <IconButton className={styles.scrollDownButton} size="small" onClick={() => scrollTo(containerRef.current!)} data-sentry-element="IconButton" data-sentry-source-file="index.tsx">
          <KeyboardArrowDownRoundedIcon className={styles.scrollDownIcon} data-sentry-element="KeyboardArrowDownRoundedIcon" data-sentry-source-file="index.tsx" />
        </IconButton>
      </section>

      <div ref={containerRef}>
        <section>
          <Typography variant="h2" align="center" className={styles.title} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {t('main:shop_by_category')}
          </Typography>

          {categories.map((category, index) => {
          return <div key={index}>
                <Category imageUrl={category.image?.src} imageTitle={category.image?.title?.[i18n.language] || category.image?.alt[i18n.language]} link={getCategoryUrl(category)} name={category.name[i18n.language]} description={category.metaDescription[i18n.language]} isReversed={index % 2 !== 0} />
                <Divider />
              </div>;
        })}
        </section>

        <PageContainer data-sentry-element="PageContainer" data-sentry-source-file="index.tsx">
          <Markdown data-sentry-element="Markdown" data-sentry-source-file="index.tsx">{text}</Markdown>
        </PageContainer>
      </div>
    </Layout>;
};
export default Home;