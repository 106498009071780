import classNames from 'clsx';
import React, { useCallback, useMemo } from 'react';
import ReactSwipe, { Props as ReactSwipeProps } from 'react-swipe';
import Markdown from 'client/components/Markdown';
import Button from 'client/elements/Button';
import Hidden from 'client/elements/Hidden';
import IconButton from 'client/elements/IconButton';
import { KeyboardArrowLeftRoundedIcon, KeyboardArrowRightRoundedIcon, RadioButtonCheckedIcon, RadioButtonUncheckedIcon } from 'client/elements/Icons';
import Link from 'client/elements/Link';
import Typography from 'client/elements/Typography';
import styles from './Gallery.module.css';
const reactSwipeStyles: ReactSwipeProps['style'] = {
  container: {
    overflow: 'hidden',
    height: '100%'
  },
  wrapper: {
    overflow: 'hidden',
    height: '100%'
  },
  child: {
    height: '100%',
    position: 'relative',
    float: 'left',
    transitionProperty: 'transform'
  }
};
export interface GalleryProps {
  slides: Array<{
    imageSrc?: string;
    imagePositionX: 'left' | 'center' | 'right';
    imagePositionY: 'top' | 'center' | 'bottom';
    title: React.ReactNode;
    description: string;
    actionText: React.ReactNode;
    actionLink: string;
  }>;
}
const Gallery = ({
  slides
}: GalleryProps) => {
  const [slideIndex, setSlideIndex] = React.useState(0);
  const reactSwipeTransitionEnd = useCallback<Exclude<Exclude<ReactSwipeProps['swipeOptions'], undefined>['transitionEnd'], undefined>>(index => setSlideIndex(index), []);
  const reactSwipeOptions = useMemo(() => ({
    startSlide: slideIndex,
    speed: 300,
    continuous: true,
    auto: 5000,
    transitionEnd: reactSwipeTransitionEnd
  }) satisfies ReactSwipeProps['swipeOptions'], [reactSwipeTransitionEnd, slideIndex]);
  const reactSwipeRef = React.useRef<ReactSwipe | null>(null);
  return <div className={styles.container} data-sentry-component="Gallery" data-sentry-source-file="Gallery.tsx">
      <ReactSwipe ref={reactSwipeRef} style={reactSwipeStyles} swipeOptions={reactSwipeOptions} data-sentry-element="ReactSwipe" data-sentry-source-file="Gallery.tsx">
        {slides.map(({
        imageSrc,
        imagePositionX: imagePositionX,
        imagePositionY,
        title,
        description,
        actionText,
        actionLink
      }, index) => {
        return <div key={`gallery-image-${index}`} className={styles.slide} style={{
          backgroundImage: `url(${imageSrc})`,
          backgroundPositionX: imagePositionX,
          backgroundPositionY: imagePositionY
        }}>
                <div className={styles.informationContainer}>
                  <div className={styles.information}>
                    <Typography variant="h2" gutterBottom={true} className={classNames(styles.text, styles.title)}>
                      {title}
                    </Typography>
                    <Hidden lgDown={true}>
                      <div className={styles.text}>
                        <Markdown>{description}</Markdown>
                      </div>
                    </Hidden>

                    <Button component={Link} href={actionLink}
              // @ts-ignore: Link prop
              underline="none" variant="contained" color="secondary">
                      {actionText}
                    </Button>
                  </div>
                </div>
              </div>;
      })}
      </ReactSwipe>

      {slides.length > 1 && <>
          <div className={styles.indicators}>
            {slides.map((_slide, index) => {
          return <IconButton key={`gallery-indicator-${index}`} color="inherit" size="small" onClick={() => reactSwipeRef.current!.slide(index, 300)}>
                  {slideIndex === index ? <RadioButtonCheckedIcon fontSize="small" className={styles.radioIcon} /> : <RadioButtonUncheckedIcon fontSize="small" className={styles.radioIcon} />}
                </IconButton>;
        })}
          </div>

          <IconButton color="inherit" className={classNames(styles.nav, styles.prev)} onClick={() => reactSwipeRef.current!.prev()} size="large">
            <KeyboardArrowLeftRoundedIcon fontSize="large" className={styles.navIcon} />
          </IconButton>
          <IconButton color="inherit" className={classNames(styles.nav, styles.next)} onClick={() => reactSwipeRef.current!.next()} size="large">
            <KeyboardArrowRightRoundedIcon fontSize="large" className={styles.navIcon} />
          </IconButton>
        </>}
    </div>;
};
export default Gallery;