import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import Home from 'client/pages/Home';
import { getServerSideTranslations } from 'client/utils/i18n';
import { getHomePage } from 'database/home-page';
import { getProductCategories } from 'database/product-categories';
export const getStaticProps = async ({
  locale,
  defaultLocale
}: GetStaticPropsContext) => {
  const [homePage, categories] = await Promise.all([getHomePage(), getProductCategories()]);
  return {
    props: {
      ...(await getServerSideTranslations(locale || defaultLocale!)),
      categories,
      metaTitle: homePage.metaTitle[locale || defaultLocale!] || '',
      metaDescription: homePage.metaDescription[locale || defaultLocale!] || '',
      text: homePage.text[locale || defaultLocale!] || '',
      gallerySlides: homePage.gallerySlides.map(gallerySlide => ({
        imageSrc: gallerySlide.image?.src,
        imagePositionX: gallerySlide.imagePositionX,
        imagePositionY: gallerySlide.imagePositionY,
        title: gallerySlide.title[locale || defaultLocale!],
        description: gallerySlide.description[locale || defaultLocale!] || '',
        actionText: gallerySlide.actionText[locale || defaultLocale!],
        actionLink: gallerySlide.actionLink
      }))
    }
  };
};
const HomePage = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
  return <Home {...props} data-sentry-element="Home" data-sentry-component="HomePage" data-sentry-source-file="index.tsx" />;
};
export default HomePage;