import classNames from 'clsx';
import Button from 'client/elements/Button';
import Grid from 'client/elements/Grid';
import Link from 'client/elements/Link';
import Typography from 'client/elements/Typography';
import { useTranslation } from 'client/utils/i18n';
import styles from './Category.module.css';
interface Category {
  imageUrl?: string;
  imageTitle?: string;
  link: string;
  name?: string;
  description?: string;
  isReversed: boolean;
}
const Category = ({
  imageUrl,
  imageTitle,
  link,
  name,
  description,
  isReversed
}: Category) => {
  const {
    t
  } = useTranslation();
  return <Grid container={true} justifyContent="center" direction={isReversed ? 'row-reverse' : 'row'} data-sentry-element="Grid" data-sentry-component="Category" data-sentry-source-file="Category.tsx">
      <Grid item={true} sm={12} md={6} className={styles.imageContainer} data-sentry-element="Grid" data-sentry-source-file="Category.tsx">
        <Link className={classNames(styles.imageLink, {
        [styles.reverse]: isReversed
      })} href={link} title={imageTitle} data-sentry-element="Link" data-sentry-source-file="Category.tsx">
          <span role="img" title={imageTitle} className={styles.image} style={{
          backgroundImage: `url(${imageUrl})`
        }} />
        </Link>
      </Grid>
      <Grid item={true} sm={12} md={6} className={classNames(styles.info, {
      [styles.reverse]: isReversed
    })} data-sentry-element="Grid" data-sentry-source-file="Category.tsx">
        <div>
          {name && <Typography variant="h3" gutterBottom={true}>
              {name}
            </Typography>}
          {description && <Typography gutterBottom={true}>{description}</Typography>}
          <Button component={Link}
        // @ts-ignore: Link component props
        underline="none" href={link} variant="contained" color="secondary" fullWidth={false} data-sentry-element="Button" data-sentry-source-file="Category.tsx">
            {t('main:view_products')}
          </Button>
        </div>
      </Grid>
    </Grid>;
};
export default Category;