import MuiTable, { TableProps as MuiTableProps } from '@mui/material/Table';
import styles from './Table.module.css';
export type TableProps = MuiTableProps;
const Table = ({
  children,
  className,
  ...props
}: TableProps) => {
  return <MuiTable {...props} className={styles.table} data-sentry-element="MuiTable" data-sentry-component="Table" data-sentry-source-file="Table.tsx">
      {children}
    </MuiTable>;
};
export default Table;