import { TableContainer } from '@mui/material';
import React, { useId } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGFM from 'remark-gfm';
import Slug from 'slug';
import Link, { LinkProps } from 'client/elements/Link';
import Paper from 'client/elements/Paper';
import Table from 'client/elements/Table';
import TableBody from 'client/elements/TableBody';
import TableCell from 'client/elements/TableCell';
import TableHead from 'client/elements/TableHead';
import TableRow from 'client/elements/TableRow';
import Typography from 'client/elements/Typography';
import styles from './Markdown.module.css';
const useSlug = (text: React.ReactNode) => {
  const textToProcess = React.useMemo(() => {
    if (text) {
      if (typeof text === 'string' || typeof text === 'number' || typeof text === 'boolean') {
        return text.toString();
      }
      if (Array.isArray(text)) {
        if (typeof text[0] === 'string' || typeof text[0] === 'number' || typeof text[0] === 'boolean') {
          return text[0].toString();
        }
      }
    }
  }, [text]);
  const slug = React.useMemo(() => {
    return textToProcess ? Slug(textToProcess, {
      lower: true
    }) : null;
  }, [textToProcess]);
  return slug;
};
type ReactMarkdownProps = React.ComponentProps<typeof ReactMarkdown>;
type Components = ReactMarkdownProps['components'];
const components: Components = {
  h1: function Heading({
    ref,
    node,
    ...props
  }) {
    const slug = useSlug(props.children);
    return <Typography {...props} id={slug || undefined} color="textPrimary" variant="h1" className={styles.title} />;
  },
  h2: function Heading({
    ref,
    node,
    ...props
  }) {
    const slug = useSlug(props.children);
    return <Typography {...props} id={slug || undefined} color="textPrimary" variant="h2" className={styles.title} />;
  },
  h3: function Heading({
    ref,
    node,
    ...props
  }) {
    const slug = useSlug(props.children);
    return <Typography {...props} id={slug || undefined} color="textPrimary" variant="h3" className={styles.title} />;
  },
  h4: function Heading({
    ref,
    node,
    ...props
  }) {
    const slug = useSlug(props.children);
    return <Typography {...props} id={slug || undefined} color="textPrimary" variant="h4" className={styles.title} />;
  },
  h5: function Heading({
    ref,
    node,
    ...props
  }) {
    const slug = useSlug(props.children);
    return <Typography {...props} id={slug || undefined} color="textPrimary" variant="h5" className={styles.title} />;
  },
  h6: function Heading({
    ref,
    node,
    ...props
  }) {
    const slug = useSlug(props.children);
    return <Typography {...props} id={slug || undefined} color="textPrimary" variant="h6" className={styles.title} />;
  },
  p: function P({
    ref,
    node,
    ...props
  }) {
    return <Typography {...props} color="textPrimary" paragraph={true} />;
  },
  li: function Li({
    ref,
    ...props
  }) {
    return <li ref={ref}>
        <Typography {...props} color="textPrimary" component="span" />
      </li>;
  },
  img: function Img({
    node,
    ...props
  }) {
    const id = useId();
    return <span role="figure" aria-labelledby={id} className={styles.imageContainer}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img alt="" {...props} className={styles.image} />
        {props.title && <Typography component="span" variant="caption" id={id} className={styles.imageCaption}>
            {props.title}
          </Typography>}
      </span>;
  },
  a: function A({
    ref,
    node,
    ...props
  }) {
    const newProps: LinkProps = {
      ...props,
      href: props.href || '',
      target: props.target as LinkProps['target'],
      color: 'primary'
    };
    return <Link {...newProps} />;
  },
  table: ({
    ref,
    node,
    ...props
  }) => <TableContainer component={Paper}>
      <Table {...props} />
    </TableContainer>,
  thead: ({
    ref,
    node,
    ...props
  }) => <TableHead {...props} />,
  tbody: ({
    ref,
    node,
    ...props
  }) => <TableBody {...props} />,
  tr: ({
    ref,
    node,
    ...props
  }) => <TableRow hover {...props} />,
  // @ts-expect-error for some reason the valign prop is transformed to vAlign here
  td: ({
    ref,
    node,
    align,
    vAlign,
    ...props
  }) => <TableCell {...props} align={align === 'char' ? undefined : align} valign={vAlign} />
};
interface MarkdownProps {
  children: string;
}
const Markdown = (props: MarkdownProps) => {
  return <ReactMarkdown {...props} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGFM]} components={components} data-sentry-element="ReactMarkdown" data-sentry-component="Markdown" data-sentry-source-file="Markdown.tsx" />;
};
export default Markdown;